import layouts from '@/layouts/defined.layouts'

const routes = [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'sites',
    path: '/sites',
    component: () => import(/* webpackPrefetch: true */ '@/views/Sites.vue'),
    title: 'Select a site | ePid',
    meta: { layout: layouts.BASIC },
  },
  {
    name: 'trialData',
    path: '/trial-data',
    component: () => import('@/views/trial/TrialData.vue'),
    title: 'Trial Data',
    meta: {
      layout: layouts.BASIC,
      monitorOnly: true,
      breadcrumb: [
        {
          name: 'Trial data extract',
          link: 'trialData',
        },
      ],
    },
  },
  {
    path: '/trial-blank-form',
    name: 'trialBlankForm',
    component: () => import('@/views/trial/TrialBlankForm.vue'),
    title: 'Blank Form',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [
        {
          name: 'Trial data extract',
          link: 'dataExtract',
        },
        {
          name: 'Blank Form',
          link: 'siteBlankForm',
        },
      ],
    },
  },
  {
    name: 'trialSponsorArchive',
    path: '/trial-archive',
    component: () => import('@/views/trial/SponsorDataArchive.vue'),
    title: 'Data archive',
    meta: {
      layout: layouts.BASIC,
      monitorOnly: true,
      breadcrumb: [
        {
          name: 'Trial data extract',
          link: 'trialData',
        },
        {
          name: 'Data archive',
          link: 'trialSponsorArchive',
        },
      ],
    },
  },
  {
    name: 'trialReports',
    path: '/trial-reports',
    component: () => import('@/views/trial/Reports.vue'),
    title: 'Trial Reports',
    meta: {
      layout: layouts.BASIC,
      monitorOnly: true,
      breadcrumb: [
        {
          name: 'Trial data extract',
          link: 'trialData',
        },
        {
          name: 'Reports',
          link: 'trialReports',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)',
    name: 'site',
    props: true,
    component: () => import('@/views/site/PatientsOverview.vue'),
    title: 'Patients overview',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [],
    },
  },
  {
    path: '/:siteNo(\\d+)/data-extract',
    name: 'dataExtract',
    props: true,
    component: () => import('@/views/site/DataExtract.vue'),
    title: 'Data extract',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [
        {
          name: 'Data extract',
          link: 'dataExtract',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/data-extract/archive',
    name: 'archive',
    props: true,
    component: () => import('@/views/site/DataArchive.vue'),
    title: 'Data Archive',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [
        {
          name: 'Data extract',
          link: 'dataExtract',
        },
        {
          name: 'Archive',
          link: 'archive',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/data-extract/blank-form',
    name: 'siteBlankForm',
    props: true,
    component: () => import('@/views/site/SiteBlankForm.vue'),
    title: 'Blank Form',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [
        {
          name: 'Data extract',
          link: 'dataExtract',
        },
        {
          name: 'Blank Form',
          link: 'siteBlankForm',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/data-extract/reports',
    name: 'reports',
    props: true,
    component: () => import('@/views/site/Reports.vue'),
    title: 'Reports',
    meta: {
      layout: layouts.BASIC,
      breadcrumb: [
        {
          name: 'Data extract',
          link: 'dataExtract',
        },
        {
          name: 'Reports',
          link: 'reports',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)',
    name: 'patient',
    props: true,
    component: () => import('@/views/patient/Patient.vue'),
    title: 'Patient detail',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/hypos',
    name: 'patientHypoOverview',
    props: true,
    component: () => import('@/views/patient/HypoOverview.vue'),
    title: 'Hypoglycaemic episodes',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'Hypo Overview', link: 'patientHypoOverview' },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/blood-glucose-values',
    name: 'patientBgmData',
    props: true,
    component: () => import('@/views/patient/SmbgData.vue'),
    title: 'Blood glucose values',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'BG values', link: 'patientBgmData' },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/prescription',
    name: 'prescription',
    props: true,
    component: () => import('@/views/patient/Prescription.vue'),
    title: 'Prescription',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'Prescription', link: 'prescription' },
      ],
    },
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/pros',
    name: 'proQuestionnaires',
    props: true,
    component: () => import('@/views/patient/ProQuestionnaires.vue'),
    title: 'PRO questionnaires',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'PRO questionnaires', link: 'proQuestionnaires' },
      ],
    },
  },

  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/settings',
    name: 'settings',
    props: true,
    component: () => import('@/views/patient/Settings.vue'),
    title: 'Settings',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'Settings', link: 'settings' },
      ],
    },
    children: [
      {
        path: 'app',
        name: 'settingsApp',
        props: true,
        component: () => import('@/views/patient/settings/AppSettingsPage.vue'),
        title: 'App settings',
        meta: {
          layout: layouts.PATIENT,
          breadcrumb: [
            {
              name: routeParams => `Patient ${routeParams.patientNo}`,
              link: 'patient',
            },
            { name: 'App settings', link: 'settingsApp' },
          ],
        },
      },
      {
        path: 'patient',
        name: 'settingsPatient',
        props: true,
        component: () =>
          import('@/views/patient/settings/PatientSettingsPage.vue'),
        title: 'Patient settings',
        meta: {
          layout: layouts.PATIENT,
          breadcrumb: [
            {
              name: routeParams => `Patient ${routeParams.patientNo}`,
              link: 'patient',
            },
            { name: 'Patient settings', link: 'settingsPatient' },
          ],
        },
      },
    ],
  },
  {
    path: '/:siteNo(\\d+)/patient/:patientNo(\\d+)/dose',
    name: 'patientDoseDiary',
    props: true,
    component: () => import('@/views/patient/DoseDiary.vue'),
    title: 'Dose diary',
    meta: {
      layout: layouts.PATIENT,
      breadcrumb: [
        {
          name: routeParams => `Patient ${routeParams.patientNo}`,
          link: 'patient',
        },
        { name: 'Dose diary', link: 'patientDoseDiary' },
      ],
    },
  },
  {
    path: '/error/403',
    name: 'notAuthorized',
    props: true,
    component: () => import('@/views/error/NotAuthorized.vue'),
    title: 'Not allowed',
    meta: { layout: layouts.BASIC },
  },
  {
    path: '/trial-coming-soon',
    name: 'preTrial',
    props: true,
    component: () => import('@/views/error/PreTrial.vue'),
    title: 'Trial coming soon',
    meta: { layout: layouts.BASIC },
  },
  {
    path: '/error/500',
    name: 'internalError',
    props: true,
    component: () => import('@/views/error/InternalError.vue'),
    title: 'Error',
    meta: { layout: layouts.BASIC },
  },
  {
    path: '/error/503',
    name: 'apiError',
    props: true,
    component: () => import('@/views/error/ApiError.vue'),
    title: 'Error',
    meta: { layout: layouts.BASIC },
  },
  {
    path: '*',
    name: 'notFound',
    props: true,
    component: () => import('@/views/error/NotFound.vue'),
    title: 'Not found',
    meta: { layout: layouts.CARD },
  },
]

export default routes
