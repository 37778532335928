<template>
  <svg width="16" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 .143S0 8.629 0 14c0 4.457 3.543 8 8 8s8-3.543 8-8C16 8.629 8 .143 8 .143zM8 20c-3.365 0-6-2.635-6-6 0-2.805 3.253-7.582 6.001-10.846C10.749 6.415 14 11.188 14 14c0 3.365-2.635 6-6 6z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenuBloodDropO',
}
</script>
