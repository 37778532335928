import Vue from 'vue'
import store from '@/store'
import { userModule } from '@/store/modules/userModule'

export default {
  pluginName: 'user-data',
  async install(vue, options) {
    const $vue = vue

    $vue.$log.debug('Fetching user data')

    const isAuthenticated = await Vue.$auth.isAuthenticated()

    if (isAuthenticated) {
      try {
        await store.dispatch(`${userModule}/loadUserRequested`)
      } catch (error) {
        $vue.$log.error(error)
        Vue.$tracking.error('load user failed', error)

        if (error.response?.status === 401) {
          // Client thought it was still authenticated.
          // Server says user is not authenticated anymore.
          const afterLoginRedirect = location.pathname + location.search
          Vue.$auth.login(afterLoginRedirect)
        }
      }
    }

    return new Promise(resolve => {
      resolve(options.callback())
    })
  },
}
