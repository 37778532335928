import axios from 'axios'

export default {
  pluginName: 'app-settings',
  install(vue, options) {
    const $vue = vue

    $vue.$log.debug('Fetching $appSettings')

    return axios.get(`/config/config.json?v=${Date.now()}`).then(r => {
      const appSettings = r.data.vueAppSettings
      // Makes app settings available inside all components as well as outside
      $vue.prototype.$appSettings = appSettings
      $vue.$appSettings = appSettings

      this.checkAppSettings(appSettings, vue.$log)

      options.callback()
    })
  },
  checkAppSettings(appSettings, log) {
    if (!appSettings.apiBaseUrl) log.error('appSettings.apiBaseUrl is missing!')
  },
}
