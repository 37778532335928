import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  getProsList(patientNumber: string) {
    const currentClientTime = dateTimeWithTimeZone()
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/pro/list', {
          params: {
            patientNumber,
            currentClientTime,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getProsList error', error, {
            patientNumber,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getProsAuditTrail(patientNumber: string, proPlannedTaskIds: string[]) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/pro/audit', {
          params: {
            patientNumber,
            proPlannedTaskIds,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getProsAuditTrail error', error, {
            patientNumber,
            proPlannedTaskIds,
          })
          reject(error)
        })
    })
  },
  getProItem(patientNumber: string, proPlannedTaskId: string) {
    const currentClientTime = dateTimeWithTimeZone()
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/pro/item', {
          params: {
            patientNumber,
            proPlannedTaskId,
            currentClientTime,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getProItem error', error, {
            patientNumber,
            proPlannedTaskId,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  reSendPros(
    patientNumber: string,
    proPlannedTaskIds: string[],
    versionReason: string
  ) {
    const currentClientTime = dateTimeWithTimeZone()
    const request = {
      patientNumber,
      currentClientTime,
      proPlannedTaskIds,
      versionReason,
    }
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/pro/send', request)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('reSendPros error', error, {
            patientNumber,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getProHistory(patientNumber: string, proPlannedTaskId: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/pro/history', {
          params: {
            patientNumber,
            proPlannedTaskId,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getProsHistory error', error, {
            patientNumber,
            proPlannedTaskId,
          })
          reject(error)
        })
    })
  },
  sendPros(
    patientNumber: string,
    proPlannedTaskIds: string[],
    versionReason: string
  ) {
    const currentClientTime = dateTimeWithTimeZone()
    const request = {
      patientNumber,
      currentClientTime,
      proPlannedTaskIds,
      versionReason,
    }
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/pro/send', request)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('sendPros error', error, {
            patientNumber,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  setReviewed(id, patientNo, currentClientTime) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/pro/review', {
          patientNumber: patientNo,
          dataItemId: id,
          currentClientTime,
          ReviewAction: 'set',
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('setReviewed error', error, {
            id,
            patientNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  undoReview(id, patientNo, currentClientTime) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/pro/review', {
          patientNumber: patientNo,
          dataItemId: id,
          currentClientTime,
          ReviewAction: 'unset',
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('undoReview error', error, {
            id,
            patientNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getReviewHistory(dataItemId) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/pro/review/history', {
          params: { dataItemId },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getReviewHistory error', error, { dataItemId })
          reject(error)
        })
    })
  },
}
