import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import NProgress from 'nprogress'

import '@/vue-declarations'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/router/router'
import store from '@/store'
import Auth from '@/plugins/auth'
import AppSettings from '@/plugins/appSettingsPlugin'
import UserData from '@/plugins/userDataPlugin'
import Tracking from '@/plugins/epidTracking'
import '@/app-styles/bundle.scss'
import i18n from './locales/i18n'
import NotificationType from '@/store/modules/notification/NotificationType'

const isProduction = process.env.NODE_ENV === 'production'
Vue.config.productionTip = false

// Keep VueLogger on top
const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}

/*  Makes logger available through:
    this.$log.debug('test', this.a, 123)
    this.$log.info('test', this.b)
    this.$log.warn('test')
    this.$log.error('test')
    this.$log.fatal('test')
    // log from external function
    Vue.$log.debug('log from function outside component.');
*/
Vue.use(VueLogger as any, loggerOptions)

try {
  const nprogressDelayStart = setTimeout(NProgress.start, 500)
  store.dispatch(`globalProgress/globalStartLoadingRequested`)

  Vue.use(Tracking)

  const onUserDataLoaded = async () => {
    const app = new Vue({
      router,
      store,
      // @ts-ignore
      vuetify,
      i18n,
      render: h => h(App),
    })

    clearTimeout(nprogressDelayStart)
    NProgress.done()
    store.dispatch('globalProgress/globalStopLoadingRequested')

    app.$mount('#app')
  }

  const onAppSettingsLoaded = async () => {
    Vue.use(Auth, { router, store })
    Vue.use(UserData, { callback: onUserDataLoaded })

    checkAndValidateSsoUrl()

    if (typeof window !== 'undefined') {
      window.onunhandledrejection = (error: PromiseRejectionEvent) => {
        Vue.$log.error(error)
      }
    }
  }

  Vue.use(AppSettings, { callback: onAppSettingsLoaded })
} catch (error) {
  Vue.$log.error(error)
  Vue.$tracking.error('main.ts caught an exception', error)
}

function checkAndValidateSsoUrl() {
  const openidConfigurationUrl = `${Vue.$appSettings.identityServerAuthority}.well-known/openid-configuration`
  window.fetch &&
    window.fetch(openidConfigurationUrl).catch(error => {
      Vue.$log.error(error)
      Vue.$tracking.error('Openid Configuration url failed', error, {
        openidConfigurationUrl,
      })

      store.dispatch('notification/spawnNotification', {
        type: NotificationType.Error,
        title: 'Single sign on error',
        message:
          'Please try again or contact support if it still does not work.',
        hasNoTimeout: true,
        action1: {
          label: 'Reload browser',
          action: () => {
            location.reload()
          },
        },
      })
    })
}
