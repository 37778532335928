export const simulateIpad = Number(process.env.VUE_APP_SIMULATE_IPAD)

const allowedUserAgents = userAgent => {
  const regexp = /Version\/(\d+\.\d+\.?[\d+]?) Safari/
  const version = userAgent.match(regexp)
  if (version && version.length > 1) {
    const versionNumber = version[1]
    const [major, minor, patch] = versionNumber.split('.')

    if (parseInt(major) < 14) {
      return false
    } else if (parseInt(major) > 14) {
      return true
    } else {
      if (parseInt(minor) > 0) {
        return true
      } else {
        return parseInt(patch) >= 2
      }
    }
  }
  return false
}

const isValidScreenSize = screen => {
  return (
    (screen.width === 834 && screen.height === 1112) || //ipad air 2019
    (screen.width === 820 && screen.height === 1180) || //ipad air 2020
    (screen.width === 810 && screen.height === 1080) //ipad 8th gen 2020
  )
}

const deviceInfo = Object.freeze({
  isTouchOnly:
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia('(hover: none)').matches,

  isValidUserAgentVersion: () => allowedUserAgents(navigator.userAgent),

  isIpadAir2019: () =>
    simulateIpad ||
    (typeof window !== 'undefined' &&
      navigator.platform === 'MacIntel' &&
      deviceInfo.isValidUserAgentVersion &&
      isValidScreenSize(window.screen) &&
      window.devicePixelRatio === 2 &&
      navigator.maxTouchPoints === 5 &&
      // @ts-ignore
      typeof navigator.standalone !== 'undefined'),
})

export default deviceInfo
