<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.59369 1.96979L1.96982 3.59366L2.81585 4.43969L4.43972 2.81582L3.59369 1.96979ZM5.61376 1.64178L4.23941 0.267435C3.88279 -0.0891841 3.30459 -0.0891874 2.94797 0.267435L0.267466 2.94794C-0.0891545 3.30456 -0.089156 3.88276 0.267466 4.23938L17.7606 21.7325C18.1172 22.0891 18.6954 22.0891 19.052 21.7325L21.7325 19.052C22.0892 18.6954 22.0892 18.1172 21.7325 17.7606L13.9472 9.97526L15.032 8.89048C15.3886 8.53386 15.3886 7.95566 15.032 7.59904L7.98998 0.556999C7.63336 0.200377 7.05516 0.20038 6.69854 0.556999L5.61376 1.64178ZM5.61376 3.98985L3.98988 5.61373L8.73078 10.3546L10.3547 8.73075L5.61376 3.98985ZM11.5287 9.90478L9.90481 11.5287L18.4063 20.0302L20.0302 18.4063L11.5287 9.90478ZM6.78779 2.81582L7.34426 2.25935L13.3297 8.24476L12.7732 8.80122L6.78779 2.81582Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPen',
  props: {
    height: { type: Number, required: false, default: 22 },
    width: { type: Number, required: false, default: 22 },
  },
}
</script>
