<template>
  <div class="application-page">
    <AppBar />

    <v-main class="main">
      <div class="basic-container mx-lg-auto col-lg-9 mx-xl-auto col-xl-7 pt-5">
        <router-view />
      </div>
    </v-main>

    <AppFooter />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import AppBar from '@/components/layout/AppBar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'

export default Vue.extend({
  name: 'BasicLayout',
  components: { AppBar, AppFooter },
})
</script>

<style lang="scss" scoped>
.basic-container {
  height: 100%;
  padding: 0;
  background-color: $nn-body-background;
}
</style>
