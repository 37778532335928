// notification:
//  type: string (the kind of alert used, this is using default vuetify types: info, success, warning, error)
//  title?: string
//  message: string (the message shown to the user)
//  timeToLive?: number (how long the message should be shown in ms - default is 5000)
//  action1?: ActionItem  { label: string, action?(): any, }
//  action2?: ActionItem { label: string, action?(): any, }
//  hasNoTimeout?: boolean (if true the notification will not timeout (timeToLive is ignored)
//  isModal?: boolean (if true the notification will block the UI and can only be closed via action

import { createNamespacedHelpers } from 'vuex'

import NotificationItem from './notification/NotificationItem'

export const notificationModule = 'notification'

const notificationGetters = ['notifications']
Object.freeze(notificationGetters)

const notificationActions = ['spawnNotification', 'removeNotification']
Object.freeze(notificationActions)

const { mapGetters, mapActions } = createNamespacedHelpers(notificationModule)

export const notificationMapGetters = () => mapGetters(notificationGetters)

export const notificationMapActions = () => mapActions(notificationActions)

export default {
  namespaced: true,
  state: { notifications: [] },
  getters: { notifications: state => state.notifications },
  mutations: {
    ADD_NOTIFICATION(state, payload) {
      state.notifications.push(payload)
    },
    REMOVE_NOTIFICATION(state, index) {
      state.notifications.splice(index, 1)
    },
  },
  actions: {
    spawnNotification(
      { commit, state, dispatch },
      notification: NotificationItem
    ) {
      if (state.notifications.length) {
        const lastNotification =
          state.notifications[state.notifications.length - 1]

        // Don't repeat last message
        if (
          lastNotification.message === notification.message &&
          lastNotification.type === notification.type
        )
          return
      }

      const id = Date.now()
      const newNotification = { id, ...notification }

      commit('ADD_NOTIFICATION', newNotification)

      if (!notification.hasNoTimeout) {
        setTimeout(() => {
          dispatch('removeNotificationById', id)
        }, notification.timeToLive || 5000)
      }
    },
    removeNotification({ commit, state }, index?: number) {
      if (!index) {
        commit('REMOVE_NOTIFICATION', state.notifications.length - 1)
      } else {
        commit('REMOVE_NOTIFICATION', index)
      }
    },
    removeNotificationById({ commit, state }, id: number) {
      const index = state.notifications.findIndex(
        notification => notification.id === id
      )

      if (index !== -1) {
        commit('REMOVE_NOTIFICATION', index)
      }
    },
  },
}
