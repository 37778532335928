import Vue from 'vue'
import store from '@/store'

export const enum logTraceSeverityLevel {
  Verbose = 'Verbose',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
  Critical = 'Critical',
}

export interface traceProps {
  message?: String
  severityLevel?: logTraceSeverityLevel
  properties?: Object
  timestamp?: String
}

const trace = ({
  message,
  severityLevel = logTraceSeverityLevel.Information,
  properties = {},
  timestamp = new Date().toISOString(),
}: traceProps = {}) => {
  if (!message) throw new Error('message must be provided')

  Object.assign(properties, getUserAndDeviceInfo())

  return new Promise((resolve, reject) => {
    const data = {
      message,
      severityLevel,
      properties: sanitizeObjectValues(properties),
      timestamp,
    }

    Vue.$http
      .post('/web/log/trace', data)
      .then(response => resolve(response.data))
      .catch(error => void reject(error))
  })
}

const error = (message: String, error: Error, properties = {}) => {
  return trace({
    message,
    properties: {
      error: error + '',
      ...properties,
    },
    severityLevel: logTraceSeverityLevel.Error,
  })
}

const componentError = (component: any, error: Error, properties = {}) => {
  return trace({
    message: `${component.$options.name} component error`,
    properties: {
      error: error + '',
      ...properties,
    },
    severityLevel: logTraceSeverityLevel.Error,
  })
}

export interface eventProps {
  name?: String
  properties?: Object
  metrics?: Object
  timestamp?: String
}

const event = ({
  name,
  properties = {},
  metrics = {},
  timestamp = new Date().toISOString(),
}: eventProps = {}) => {
  return new Promise((resolve, reject) => {
    Object.assign(properties, getUserAndDeviceInfo())

    const data = {
      name,
      properties: sanitizeObjectValues(properties),
      metrics: sanitizeObjectValues(metrics),
      timestamp,
    }

    Vue.$http
      .post('/web/log/event', data)
      .then(response => resolve(response.data))
      .catch(error => void reject(error))
  })
}

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param Vue
 * @param options
 */
function install(Vue, options = {} as any) {
  const tracking = { trace, error, componentError, event }
  Vue.$tracking = tracking
  Vue.prototype.$tracking = tracking

  const router = options.router

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue)
    } else {
      router.onReady(() => setupPageTracking(options, Vue))
    }
  }
}

/**
 * Track route changes as page views
 */
function setupPageTracking(options, Vue) {
  const router = options.router

  const baseName = options.baseName || '(Vue App)'

  router.beforeEach((route, from, next) => {
    const name = baseName + ' / ' + route.name
    const url = location.protocol + '//' + location.host + route.fullPath
    Vue.$tracking.event({
      name,
      properties: {
        url,
      },
    })
    next()
  })
}

function sanitizeObjectValues(object: Object): Object {
  const sanitized = { ...object }
  for (const key of Object.keys(sanitized)) {
    switch (typeof sanitized[key]) {
      case 'object':
        sanitized[key] = JSON.stringify(sanitized[key])
        break
      case 'symbol':
        sanitized[key] = sanitized[key].toString()
        break
      default:
        sanitized[key] = sanitized[key] + ''
    }
  }
  return sanitized
}

function getUserAndDeviceInfo() {
  const { name, email } = store.getters['user/user'] || {}
  return {
    loggedInUser: { name, email },
    userAgent: window.navigator.userAgent,
  }
}

export default install
