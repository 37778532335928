import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    // https://vuetifyjs.com/en/customization/icons
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#001965',
        secondary: '#005AD2',
        accent: '#82B1FF',
        error: '#E6553F',
        info: '#C2DEEA',
        success: '#3F9C35',
        warning: '#EAAB00',
        default: '#EBF2FB',
      },
    },
  },
})
