import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import loadingState from '@/constants/loadingState'

export const userModule = 'user'

const userGetters = [
  'user',
  'userEmail',
  'userName',
  'userIsMonitor',
  'userShortName',
  'userTrialNumber',
  'userSites',
  'isUserLoading',
  'isUserLoaded',
  'isUserErrored',
]
Object.freeze(userGetters)

const { mapGetters } = createNamespacedHelpers(userModule)

export const userMapGetters = () => mapGetters(userGetters)

export default {
  namespaced: true,
  state: { user: {}, loadingState: loadingState.INITIAL },
  getters: {
    user: state => state.user || {},
    userEmail: state => state.user?.email || '',
    userShortName: state => state.user?.shortName || '',
    userName: state => state.user?.name || '',
    userIsMonitor: state => state.user?.isMonitor || false,
    userTrialNumber: state => state.user?.trialNumber || '',
    userSites: state => state.user?.sites || [],
    isUserLoading: state => state.loadingState === loadingState.LOADING,
    isUserLoaded: state => state.loadingState === loadingState.LOAD_SUCCEEDED,
    isUserErrored: state => state.loadingState === loadingState.LOAD_ERRORED,
  },
  mutations: {
    LOAD_USER(state) {
      state.loadingState = loadingState.LOADING
    },
    UPDATE_USER(state, payload) {
      state.user = payload?.user || {}
      state.loadingState = loadingState.LOAD_SUCCEEDED
    },
    UPDATE_USER_FAILED(state) {
      state.loadingState = loadingState.LOAD_ERRORED
    },
  },
  actions: {
    async loadUserRequested({ commit }) {
      return new Promise((resolve, reject) => {
        commit('LOAD_USER')
        Vue.$http
          .post('/web/user', {})
          .then(response => {
            commit('UPDATE_USER', response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit('UPDATE_USER_FAILED', error)
            reject(error)
          })
      })
    },
  },
}
