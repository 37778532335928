import { createNamespacedHelpers } from 'vuex'
import loadingState from '@/constants/loadingState'
import service from '@/services/hypo-service'

export const hypoListModule = 'hypoList'

const hypoListGetters = [
  'hypoListItems',
  'hypoListNonReviewedItems',
  'hypoListNoOfSevereHypos',
  'hypoListLoadingState',
  'hypoListIsLoading',
  'hypoListLoadErrored',
]
Object.freeze(hypoListGetters)

const hypoListActions = [
  'hypoListLoadDataRequested',
  'hypoListUpdateReviewStatus',
]
Object.freeze(hypoListActions)

const { mapGetters, mapActions } = createNamespacedHelpers(hypoListModule)

export const hypoListMapGetters = () => mapGetters(hypoListGetters)

export const hypoListMapActions = () => mapActions(hypoListActions)

let latestPatientNumberRequested: string = null

interface HypoListState {
  items: Array<any>
  patientNumber?: string
  loadingState: string
}

interface IReviewApiResponse {
  hypoId: number
  result: object
}

export default {
  namespaced: true,
  state: { items: [], loadingState: loadingState.INITIAL },
  getters: {
    hypoListItems: (state: HypoListState) => {
      return state.items
    },
    hypoListNonReviewedItems: (state: HypoListState) => {
      return state.items.filter(
        x => !x.hypoId || (x.review && x.review.isReviewPending && !x.isDeleted)
      )
    },
    hypoListNoOfSevereHypos: (state: HypoListState) => {
      return state.items.filter(x => x.isSevere && !x.isDeleted)
    },
    hypoListLoadingState: (state: HypoListState) => {
      return state.loadingState
    },
    hypoListIsLoading: (state: HypoListState) => {
      return state.loadingState === loadingState.LOADING
    },
    hypoListLoadErrored: (state: HypoListState) => {
      return state.loadingState === loadingState.LOAD_ERRORED
    },
  },
  mutations: {
    LOAD_HYPOLIST(state: HypoListState, patientNumber: string) {
      latestPatientNumberRequested = patientNumber
      state.loadingState = loadingState.LOADING
      state.items = []
    },
    UPDATE_HYPOLIST(state: HypoListState, data) {
      state.items = data?.items || []
      state.loadingState = loadingState.LOAD_SUCCEEDED
    },
    UPDATE_HYPOLIST_FAILED(state: HypoListState) {
      state.loadingState = loadingState.LOAD_ERRORED
    },
    UPDATE_REVIEW_STATUS(state: HypoListState, response: IReviewApiResponse) {
      const hypo = state.items.find(x => x.hypoId === response.hypoId)

      if (hypo) {
        hypo.review = response.result
      }
    },
  },
  actions: {
    hypoListUpdateReviewStatus({ commit }, response: IReviewApiResponse) {
      commit('UPDATE_REVIEW_STATUS', response)
    },
    async hypoListLoadDataRequested(
      { commit, dispatch },
      patientNumber: string
    ) {
      commit('LOAD_HYPOLIST', patientNumber)
      dispatch(`globalProgress/globalStartLoadingRequested`, null, {
        root: true,
      })

      try {
        const response: {
          items?: any
          patientNumber?: string
        } = await service.getHypoList(patientNumber)

        if (response?.patientNumber === latestPatientNumberRequested) {
          commit('UPDATE_HYPOLIST', response)
        }
      } catch (error) {
        commit('UPDATE_HYPOLIST_FAILED', error)
      } finally {
        dispatch('globalProgress/globalStopLoadingRequested', null, {
          root: true,
        })
      }
    },
  },
}
