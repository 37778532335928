import Vue from 'vue'
import Vuex from 'vuex'

import notification from './modules/notificationModule'
import trialSettings from './modules/trialSettingsModule'
import newPatient from './modules/newPatientModule'
import user from './modules/userModule'
import selectedPatient from './modules/selectedPatientModule'
import hypoList from './modules/hypoListModule'
import proList from './modules/proListModule'
import globalProgress from './modules/globalProgressModule'
import treatmentSettings from './modules/treatmentSettingsModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    notification,
    trialSettings,
    newPatient,
    user,
    selectedPatient,
    hypoList,
    proList,
    globalProgress,
    treatmentSettings,
  },
})
