<template>
  <div class="application-page" data-testid="patient-layout">
    <AppBar />

    <BoxedDiv class="app-page">
      <v-navigation-drawer
        stateless
        app
        permanent
        clipped
        color="white"
        width="72"
        class="not-left"
      >
        <v-layout fill-height column>
          <v-list nav dense>
            <PatientNavigation :patientNo="currentPatientNo" />
          </v-list>
        </v-layout>
      </v-navigation-drawer>

      <v-main>
        <router-view />
      </v-main>

      <AppFooter />
    </BoxedDiv>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import AppBar from '@/components/layout/AppBar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import PatientNavigation from '@/components/patient/PatientNavigation.vue'
import { selectedPatientMapActions } from '@/store/modules/selectedPatientModule'
import BoxedDiv from '@/components/layout/BoxedDiv.vue'

export default Vue.extend({
  name: 'PatientLayout',
  components: {
    BoxedDiv,
    PatientNavigation,
    AppFooter,
    AppBar,
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (
        newRoute.params.patientNo &&
        newRoute.params.patientNo !== oldRoute?.params?.patientNo
      ) {
        this.loadPatientConfigurationRequested(newRoute.params.patientNo)
      }
    },
  },
  computed: {
    currentPatientNo() {
      return this.$route.params.patientNo
    },
  },

  methods: {
    ...selectedPatientMapActions(),
  },
  created() {
    this.loadPatientConfigurationRequested(this.currentPatientNo)
  },
  beforeDestroy() {
    this.clearPatientConfigurationRequested()
  },
})
</script>

<style lang="scss" scoped>
.v-list--nav {
  padding: 0;
}

@media (min-width: #{$screen-lg-min}) {
  .app-page {
    background-color: $nn-body-background;
    padding-left: 0;
  }

  .v-main {
    min-height: calc(100vh - 48px);
  }
}

.not-left {
  @media (min-width: #{$screen-lg-min}) {
    left: initial;
  }
}
</style>
