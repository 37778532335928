import { createNamespacedHelpers } from 'vuex'
import AuditLog from '@/utils/audit/AuditLog'

import prescriptionType from '@/constants/prescriptionType'
import dosingDayOptions from '@/constants/dosingDayOptions'

export const treatmentSettingsModule = 'treatmentSettings'

const treatmentSettingsGetters = [
  'treatmentSettingsInfo',
  'treatmentSettingsInfoAuditEntries',
  'treatmentSettingsDoseRules',
  'treatmentSettingsPrescription',
]
Object.freeze(treatmentSettingsGetters)

const treatmentSettingsActions = [
  'treatmentSettingsUpdateInfo',
  'treatmentSettingsUpdateInfoAuditLog',
  'treatmentSettingsUpdateInitialDoseToggle',
  'treatmentSettingsClearAll',
  'treatmentSettingsUpdateDoseRules',
  'treatmentSettingsUpdatePrescriptionValue',
  'treatmentSettingsUpdateCarbCountValues',
  'treatmentSettingsUpdatePrescriptionBloodGlucoseUnit',
  'treatmentSettingsUpdatePrescriptionDosingDay',
  'treatmentSettingsUpdatePrescriptionAuditLog',
]
Object.freeze(treatmentSettingsActions)

const { mapGetters, mapActions } = createNamespacedHelpers(
  treatmentSettingsModule
)

export const treatmentSettingsMapGetters = () =>
  mapGetters(treatmentSettingsGetters)

export const treatmentSettingsMapActions = () =>
  mapActions(treatmentSettingsActions)

export const createInitialDoseRules = () => []

export const createInitialPrescriptions = () => {}

export const createInitialInfo = () => ({
  treatmentArm: null,
  dosingRegimen: null,
  versionReason: '',
  versionReasonProvided: '',
})

export const createInitialState = () => ({
  doseRules: createInitialDoseRules(),
  prescriptions: createInitialPrescriptions(),
  info: createInitialInfo(),
  infoAuditLog: new AuditLog(),
})

export default {
  namespaced: true,
  state: createInitialState(),
  getters: {
    treatmentSettingsInfo: state => state.info,
    treatmentSettingsInfoAuditEntries: state =>
      state.infoAuditLog.getAuditEntries(),
    treatmentSettingsDoseRules: state => state.doseRules,
    treatmentSettingsPrescription: state => id => {
      return state.prescriptions[id]
    },
  },

  mutations: {
    CLEAR_ALL(state) {
      resetToInitialState(state)
    },
    UPDATE_INFO(state, fields = {}) {
      state.info = { ...state.info, ...fields }
    },
    UPDATE_INFO_AUDIT_LOG(state, audit = { name: null, value: null }) {
      state.infoAuditLog.log(audit.name, audit.value)
    },
    UPDATE_INITIAL_DOSE_TOGGLE(
      state,
      { id = null, name = null, enabled = null } = {}
    ) {
      const obj = state.prescriptions[id]
      obj[name].enabled = enabled
      state.prescriptions = { ...state.prescriptions }
    },
    SET_INITIAL_PRESCRIPTION_VALUE(
      state,
      { id = null, value = null, key = null } = {}
    ) {
      const obj = state.prescriptions[id]
      if (key) {
        obj[key].value = value
      } else {
        obj.value = value
      }

      state.prescriptions = { ...state.prescriptions }
    },
    SET_INITIAL_CARB_COUNT_VALUES(
      state,
      { id = null, key = null, object = {} } = {}
    ) {
      const obj = state.prescriptions[id]
      obj[key] = { ...obj[key], ...object }
      state.prescriptions = { ...state.prescriptions }
    },
    SET_INITIAL_PRESCRIPTION_BG_UNIT(state, { id = null, value = null } = {}) {
      const obj = state.prescriptions[id]
      obj.bloodGlucoseUnit = value
      state.prescriptions = { ...state.prescriptions }
    },
    SET_INITIAL_PRESCRIPTION_DOSING_DAY(
      state,
      { id = null, value = null } = {}
    ) {
      const obj = state.prescriptions[id]
      obj.dosingDay = value
      state.prescriptions = { ...state.prescriptions }
    },
    UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG(
      state,
      { id = null, audit = { name: null, value: null } }
    ) {
      const obj = state.prescriptions[id]
      obj.auditLog.log(audit.name, audit.value)
    },
    UPDATE_DOSE_RULES(state, payload = []) {
      state.doseRules = [...payload]
      state.prescriptions = {}
      for (const rule of state.doseRules) {
        const doses = {
          dosingDay: null,
          auditLog: new AuditLog(),
          bloodGlucoseUnit: null,
        }
        for (const config of rule.doseConfiguration) {
          if (rule.type === prescriptionType.BOLUS_CARB_COUNT) {
            doses[config.timepoint] = {
              insulinToCarbRatio: null,
              insulinSensitivityFactor: null,
              enabled: config.isActive,
            }
          } else if (rule.type === prescriptionType.BOLUS) {
            doses[config.timepoint] = {
              value: null,
              enabled: config.isActive,
            }
          } else {
            Object.assign(doses, {
              value: null,
              enabled: config.isActive,
            })
          }
        }
        state.prescriptions[rule.prescriptionConfigurationId] = { ...doses }
        const today = new Date().getDay()
        if (rule.type === prescriptionType.BASAL_ONCE_WEEKLY) {
          state.prescriptions[rule.prescriptionConfigurationId].dosingDay =
            dosingDayOptions[today].value
        }
      }
    },
  },

  actions: {
    treatmentSettingsClearAll({ commit }) {
      commit('CLEAR_ALL')
    },
    treatmentSettingsUpdateInfo({ commit }, payload) {
      commit('UPDATE_INFO', payload)
    },
    treatmentSettingsUpdateInfoAuditLog({ commit }, payload) {
      commit('UPDATE_INFO_AUDIT_LOG', payload)
    },
    treatmentSettingsUpdateInitialDoseToggle({ commit }, payload) {
      commit('UPDATE_INITIAL_DOSE_TOGGLE', payload)
      commit('UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG', {
        id: payload.id,
        audit: {
          name: `PRESCRIBE_DOSE:${payload.name.toUpperCase()}_NOT_APPLICABLE`,
          value: !payload.enabled,
        },
      })
    },
    treatmentSettingsUpdateDoseRules({ commit }, payload) {
      commit('UPDATE_DOSE_RULES', payload)
    },
    treatmentSettingsUpdatePrescriptionValue({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_VALUE', payload)
    },
    treatmentSettingsUpdateCarbCountValues({ commit }, payload) {
      commit('SET_INITIAL_CARB_COUNT_VALUES', payload)
    },
    treatmentSettingsUpdatePrescriptionBloodGlucoseUnit({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_BG_UNIT', payload)
    },
    treatmentSettingsUpdatePrescriptionDosingDay({ commit }, payload) {
      commit('SET_INITIAL_PRESCRIPTION_DOSING_DAY', payload)
    },
    treatmentSettingsUpdatePrescriptionAuditLog({ commit }, payload) {
      commit('UPDATE_INITIAL_PRESCRIPTION_AUDIT_LOG', payload)
    },
  },
}

const resetToInitialState = state => {
  const initialState = createInitialState()
  Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
}
