import { createNamespacedHelpers } from 'vuex'

export const loadingProgress = 'globalProgress'

const loadingGetters = ['showLoadingProgress']
Object.freeze(loadingGetters)

const loadingActions = [
  'globalStartLoadingRequested',
  'globalStopLoadingRequested',
  'globalClearAllLoadingRequested',
]
Object.freeze(loadingActions)

const { mapGetters, mapActions } = createNamespacedHelpers(loadingProgress)

export const globalProgressMapGetters = () => mapGetters(loadingGetters)

export const globalProgressMapActions = () => mapActions(loadingActions)

interface IProgressState {
  loadingCount: number
}

let setLoadingTimeoutHandle = null

export default {
  namespaced: true,
  state: { loadingCount: 0 },
  getters: {
    showLoadingProgress: state => state.loadingCount > 0,
  },
  mutations: {
    CLEAR_LOADING(state: IProgressState) {
      state.loadingCount = 0
    },
    SET_LOADING(state: IProgressState) {
      state.loadingCount += 1
    },
    SET_NOT_LOADING(state: IProgressState) {
      state.loadingCount -= 1
    },
  },
  actions: {
    globalStartLoadingRequested({ commit }) {
      setLoadingTimeoutHandle = setTimeout(() => {
        commit('SET_LOADING')
      }, 500)
    },
    globalStopLoadingRequested({ commit }) {
      commit('SET_NOT_LOADING')
    },
    globalClearAllLoadingRequested({ commit }) {
      if (setLoadingTimeoutHandle) clearTimeout(setLoadingTimeoutHandle)

      commit('CLEAR_LOADING')
    },
  },
}
