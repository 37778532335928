const userRole = {
  StudyCoordinator: 'StudyCoordinator',
  DataManager: 'DataManager',
  Monitor: 'Monitor',
  TitrationSurveillance: 'TitrationSurveillance',
  PrincipalInvestigator: 'PrincipalInvestigator',
  SubInvestigator: 'SubInvestigator',
  Safety: 'Safety',
  TrialManager: 'TrialManager',
  SystemAdministrator: 'SystemAdministrator',
  Patient: 'Patient',
  SystemUser: 'SystemUser',
}
Object.freeze(userRole)

export function rolesText(role) {
  switch (role) {
    case userRole.StudyCoordinator:
      return 'Study Coordinator'
    case userRole.DataManager:
      return 'Data Manager'
    case userRole.TitrationSurveillance:
      return 'Titration Surveillance'
    case userRole.PrincipalInvestigator:
      return 'Principal Investigator'
    case userRole.SubInvestigator:
      return 'Sub-investigator'
    case userRole.TrialManager:
      return 'Trial Manager'
    case userRole.SystemAdministrator:
      return 'System Admin'
    case userRole.SystemUser:
      return 'System'
    default:
      return role
  }
}
Object.freeze(rolesText)

export default userRole
