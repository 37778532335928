import axios from 'axios'
import { UserManager, WebStorageStateStore } from 'oidc-client'
import qs from 'qs'
import Vue from 'vue'

import layouts from '@/layouts/defined.layouts'
import store from '@/store'
import { userModule } from '@/store/modules/userModule'
import service from '@/services/auth-service'
import NotificationType from '@/store/modules/notification/NotificationType'

const setSessionExpired = (store, auth, redirectTo: string) => {
  if (!redirectTo) redirectTo = location.pathname + location.search
  store.dispatch('notification/spawnNotification', {
    type: NotificationType.Error,
    title: 'Session expired',
    message:
      'Your login session has expired. Please log in to Epid Dashboard again.',
    hasNoTimeout: true,
    action1: {
      label: 'Goto login',
      action: () => {
        auth.login(redirectTo)
      },
    },
  })
}

class ApiAuthService {
  userManager = null
  addAccessTokenExpired = null
  siteContextToken = null
  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: Vue.$appSettings.identityServerAuthority,
      client_id: 'epid_dashboard_' + Vue.$appSettings.environmentName,
      redirect_uri: Vue.$appSettings.dashboardBaseUrl + '/signed-in',
      automaticSilentRenew: true,
      silent_redirect_uri:
        Vue.$appSettings.dashboardBaseUrl + '/silent-auth-token-renew',
      response_type: 'id_token token',
      scope: 'openid profile ' + Vue.$appSettings.identityServerApiAudience,
      post_logout_redirect_uri: Vue.$appSettings.dashboardBaseUrl + '/',
    }

    this.userManager = new UserManager(settings)
    this.addAccessTokenExpired = () => {
      this.userManager.events.addAccessTokenExpired(() => {
        setSessionExpired(store, this, null)
      })
    }
  }

  /**
   * Checks whether or not a user is currently logged in.
   *
   * Returns a promise which will be resolved to true/false or be rejected with an error.
   */
  isAuthenticated() {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(user => {
          if (user === null || user.expired || !user.access_token) {
            resolve(false)
          } else resolve(true)
        })
        .catch(error => reject(error))
    })
  }

  login(redirect) {
    this.userManager.signinRedirect({ data: { redirect } })
  }

  logout(redirect) {
    this.userManager.signoutRedirect({ data: { redirect } })
  }

  /**
   * Handles the redirect from the OAuth server after a user logged in.
   */
  handleLoginRedirect() {
    return new Promise((resolve, reject) => {
      this.userManager
        .signinRedirectCallback()
        .then(user => {
          store
            .dispatch(`${userModule}/loadUserRequested`)
            .then(responseData => {
              Vue.$log.debug('sign-in response success', user, responseData)
              resolve(user.state?.redirect || '/')
            })
            .catch(error => {
              Vue.$log.error(error)
              Vue.$tracking.error('sign-in response failed', error, { user })
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  handleSigninSilentCallback() {
    return this.userManager.signinSilentCallback()
  }

  acquireToken() {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(user => {
          resolve(user?.access_token)
        })
        .catch(error => reject(error))
    })
  }

  acquireTokenRedirect() {
    return this.login(location.href)
  }

  setSiteContextToken(token) {
    this.siteContextToken = token
  }

  getSiteContextToken() {
    return this.siteContextToken
  }
}

async function getConfig(config, auth, requiresInteraction) {
  if (config.method === 'get') {
    const prefix = config.url.indexOf('?') > -1 ? '&' : '?'
    config.url += `${prefix}cache=${Date.now()}`
  }

  const openApiUrls = ['/web/log/trace', '/web/log/event']
  try {
    if (!openApiUrls.includes(config.url)) {
      const tokenResponse = await auth.acquireToken()
      if (tokenResponse) {
        config.headers.Authorization = `Bearer ${tokenResponse}`
      }
    }

    config.headers.currentRelease = Vue.$appSettings.currentRelease

    const contextToken = auth.getSiteContextToken()
    if (contextToken) {
      config.headers.SiteContext = `${contextToken}`
    }
    return config
  } catch (error) {
    Vue.$log.debug(error)
    // Call acquireTokenRedirect in case of acquireToken failure
    if (requiresInteraction(error.errorCode)) {
      auth.acquireTokenRedirect()
    }
  }
}

export default {
  pluginName: 'auth',
  setSessionExpired,
  install(vue, options) {
    const $vue = vue
    const { router, store } = options

    const auth = new ApiAuthService()

    const requiresInteraction = errorCode => {
      Vue.$log.debug(errorCode)
      if (!errorCode?.length) {
        return false
      }
      return (
        errorCode === 'consent_required' ||
        errorCode === 'interaction_required' ||
        errorCode === 'login_required'
      )
    }

    axios.interceptors.request.use(
      async config => await getConfig(config, auth, requiresInteraction),
      error => Promise.reject(error)
    )

    const axiosConfig = {
      baseURL: Vue.$appSettings.apiBaseUrl,
      paramsSerializer: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        serialize: (params: Record<string, any>, options?: any) => {
          return qs.stringify(params, { allowDots: true })
        },
      },
    }

    const axiosInstance = axios.create(axiosConfig)
    axiosInstance.interceptors.request.use(
      async config => await getConfig(config, auth, requiresInteraction),
      error => Promise.reject(error)
    )

    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (!error.response && error?.message !== 'canceled') {
          store.dispatch('notification/spawnNotification', {
            type: NotificationType.Error,
            title: 'Network error',
            message: 'Check your network connection and try again',
          })
        } else if (error.response.status === 401) {
          this.setSessionExpired(store, auth)
        } else if (error.response.status === 426) {
          store.dispatch('notification/spawnNotification', {
            type: NotificationType.Info,
            title: 'New version is available',
            message:
              'The new version of the ePID dashboard contains bug fixes and provides a better experience.',
            hasNoTimeout: true,
            isModal: true,
            action1: {
              label: 'Update now',
              action: () => {
                window.location.reload()
              },
            },
          })
        } else if (error.response.status === 403) {
          //hack to try to avoid showing notAuthorized when downloading archive
          if (error.config.responseType !== 'arraybuffer') {
            router.push({ name: 'notAuthorized' })
          }
        } else if (error.response.status === 500) {
          if (error.response.data?.errors?.length) {
            for (const err of error.response.data.errors) {
              store.dispatch('notification/spawnNotification', {
                type: NotificationType.Error,
                title: 'Error',
                message: err.message,
                timeToLive: 10000,
              })
            }
          }
        }

        return Promise.reject(error)
      }
    )

    // Generic axios
    $vue.prototype.$http = axiosInstance
    $vue.$http = axiosInstance

    // Makes auth available inside all components as well as outside
    $vue.prototype.$auth = auth
    $vue.$auth = auth

    if (router && store) {
      // Add global route guard
      router.beforeEach(async (to, from, next) => {
        if (Vue.$appSettings.preTrial) {
          if (to.name !== 'preTrial') {
            next({ name: 'preTrial' })
          } else {
            next()
          }
        } else if (to.name === 'login') {
          Vue.$auth.login(to.query?.redirect || '/')
        } else if (to.path === '/signed-in') {
          /*
             Inform the authentication service that a user logged in.
             Afterwards we send the user to the requested page
          */
          store.dispatch(`globalProgress/globalStartLoadingRequested`)
          Vue.$auth
            .handleLoginRedirect()
            .then(redirect => next(redirect))
            .catch(error => {
              Vue.$log.error(error)
              Vue.$tracking.error('handleLoginRedirect failed', error)

              next({ name: 'home' })
            })
            .finally(() => {
              store.dispatch('globalProgress/globalStopLoadingRequested')
            })
        } else if (to.path === '/silent-auth-token-renew') {
          // Inform the authentication service that a user is renewing
          Vue.$auth
            .handleSigninSilentCallback()
            .then(result => {
              Vue.$log.debug(result)
            })
            .catch(error => {
              Vue.$log.error(error)
              Vue.$tracking.error('handleSigninSilentCallback failed', error)
            })
        } else {
          const publicPageRoutes = ['/signed-in']
          const publicPageNames = [
            'notAuthorized',
            'notFound',
            'apiError',
            'internalError',
            'preTrial',
          ]
          const authRequired =
            !publicPageRoutes.includes(to.path) ||
            !publicPageNames.includes(to.name)

          const isAuthenticated = await auth.isAuthenticated()

          // Ensure user is logged in
          if (authRequired && !isAuthenticated) {
            next(false)
            auth.login(to.fullPath)
          } else if (to.path === '/') {
            auth.setSiteContextToken(null)
            const userEmail = store.getters['user/user']?.userEmail || []

            if (userEmail != null) {
              next({ name: 'sites' })
            } else {
              next({ name: 'notAuthorized' })
            }
          } else if (to.meta.monitorOnly) {
            const isMonitor = store.getters['user/user']?.isMonitor || false
            if (!isMonitor) {
              next({ name: 'notAuthorized' })
            } else {
              next()
            }
          } else if (to.params.siteNo) {
            auth.setSiteContextToken(to.params.siteNo)
            store.dispatch(`globalProgress/globalStartLoadingRequested`)
            service
              .allowSiteAccess(to.params.siteNo, to.params.patientNo)
              .then(() => {
                store.dispatch(`trialSettings/updateTrialSettingsRequested`, {
                  siteNo: to.params.siteNo,
                })
                next()
              })
              .catch(error => {
                Vue.$log.error(error)
                next(false)
                if (error.response?.status === 401) {
                  this.setSessionExpired(store, auth, to.fullPath)
                } else {
                  sessionStorage.setItem(
                    'error:data',
                    JSON.stringify(error ?? '')
                  )
                }
              })
              .finally(() => {
                store.dispatch('globalProgress/globalStopLoadingRequested')
              })
          } else {
            auth.setSiteContextToken(null)
            store.dispatch(`trialSettings/updateTrialSettingsRequested`, {
              siteNo: null,
            })
            next()
          }
        }
      })

      router.afterEach(to => {
        if (
          to.meta.layout === layouts.PATIENT ||
          to.meta.layout === layouts.BASIC
        ) {
          sessionStorage.setItem('last:path', to.path)
        }
      })
    }
  },
}
