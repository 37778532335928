<template>
  <div class="pa-0 mx-lg-auto col-lg-9 mx-xl-auto col-xl-7">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BoxedDiv',
}
</script>
