<template>
  <div class="application-page">
    <AppBar />

    <v-main>
      <v-container class="card-container">
        <div class="background-image-wrapper">
          <img
            src="/img/illustration-care.svg"
            class="background-image"
            alt=""
            aria-hidden="true"
          />
        </div>
        <v-card class="mx-auto rounded-xl" max-width="480">
          <v-card-text class="pa-8 text-dark">
            <router-view />
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>

    <AppFooter />
  </div>
</template>

<script>
import AppBar from '@/components/layout/AppBar.vue'
import AppFooter from '@/components/layout/AppFooter.vue'

export default {
  name: 'CardLayout',
  components: { AppBar, AppFooter },
}
</script>

<style scoped lang="scss">
.card-container {
  position: relative;
  height: 100%;
  padding: 64px 12px 12px;
}

.background-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.background-image {
  display: block;
  position: absolute;
  left: 50%;
  max-width: 640px;
  width: 100%;
  height: auto;
  transform: translateX(-50%);
  bottom: -140px;
  @include ipad-landscape {
    bottom: -36px;
  }
}
</style>
