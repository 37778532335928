import Vue from 'vue'

const authService = {
  allowSiteAccess(siteNo, patientNo) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/permission/site', {
          siteNumber: siteNo,
          patientNumber: patientNo,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('allowSiteAccess error', error, {
            siteNo,
            patientNo,
          })
          reject(error)
        })
    })
  },
}

export default authService
