const dosingDayOptions = Object.freeze([
  { value: 'sunday', text: 'Sunday' },
  { value: 'monday', text: 'Monday' },
  { value: 'tuesday', text: 'Tuesday' },
  { value: 'wednesday', text: 'Wednesday' },
  { value: 'thursday', text: 'Thursday' },
  { value: 'friday', text: 'Friday' },
  { value: 'saturday', text: 'Saturday' },
])

export default Object.freeze(dosingDayOptions)
