<template>
  <v-footer
    color="white"
    app
    height="48"
    class="pa-0 pl-10 pr-4 mx-lg-auto col-lg-9 mx-xl-auto col-xl-7"
  >
    <v-row no-gutters class="d-flex justify-space-between align-center">
      <v-col class="text">
        © Copyright 2020 Novo Nordisk. All rights reserved.
        <small class="d-block grey--text">{{ buildNo }}</small>
      </v-col>

      <LogoSmall />
    </v-row>
  </v-footer>
</template>

<script>
import Vue from 'vue'

import LogoSmall from '../images/LogoSmall'

export default {
  name: 'AppFooter',
  components: { LogoSmall },
  data() {
    return {
      buildNo: `Build: ${Vue.$appSettings.build}`,
    }
  },
}
</script>

<style lang="scss" scoped>
.text {
  font-size: 12px;
}
</style>
