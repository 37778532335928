import AuditEntry from '@/utils/audit/AuditEntry'

export default class AuditLog {
  private readonly items: Array<AuditEntry>

  constructor() {
    this.items = new Array<AuditEntry>()
  }

  public getAuditEntries() {
    return this.items.slice()
  }

  public log(fieldName: string, value: string) {
    if (this.previousEntryByFieldName(fieldName)?.newValue != value) {
      this.logEntry(fieldName, value)
    }
  }

  public logReset(fieldName: string) {
    const previousEntryByFieldName = this.previousEntryByFieldName(fieldName)

    if (previousEntryByFieldName && previousEntryByFieldName.newValue != '') {
      this.logEntry(fieldName, '')
    }
  }

  private previousEntryByFieldName(fieldName: string) {
    return this.items
      .slice()
      .reverse()
      .find(x => x.fieldName === fieldName)
  }

  private logEntry(fieldName: string, value: string) {
    const id = this.items.length + 1
    const entry = new AuditEntry(id, fieldName, value)
    this.items.push(entry)
  }
}
