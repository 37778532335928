import Vue from 'vue'

const hypoService = {
  getHypoList(patientNumber) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/hypo/list', {
          params: { patientNumber },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getHypoList error', error, { patientNumber })
          reject(error)
        })
    })
  },
  getHypo(id) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/hypo', { params: { id } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getHypo error', error, { id })
          reject(error)
        })
    })
  },
  getHypoHistory(id) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/hypo/history', { params: { id } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getHypoHistory error', error, { id })
          reject(error)
        })
    })
  },
  setReviewed(id, patientNo, currentClientTime) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/hypo/review', {
          PatientNumber: patientNo,
          HypoId: id,
          currentClientTime,
          ReviewAction: 'set',
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('setReviewed error', error, {
            id,
            patientNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  undoReview(id, patientNo, currentClientTime) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/hypo/review', {
          PatientNumber: patientNo,
          HypoId: id,
          currentClientTime,
          ReviewAction: 'unset',
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('undoReview error', error, {
            id,
            patientNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getReviewHistory(hypoId) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/hypo/review/history', {
          params: { hypoId },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getReviewHistory error', error, { hypoId })
          reject(error)
        })
    })
  },
}

export default hypoService
