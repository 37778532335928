import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import AuditEntry from '@/utils/audit/AuditEntry'

export default {
  getPatient(patientNumber: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get('/web/patient', {
          params: { patientNumber, currentClientTime },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPatient error', error, {
            patientNumber,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getConfiguration(patientNumber: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/configuration', { params: { patientNumber } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getConfiguration error', error, {
            patientNumber,
          })
          reject(error)
        })
    })
  },
  getHistory(patientNumber: string, scopes: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/history', { params: { patientNumber, scopes } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getHistory error', error, {
            patientNumber,
            scopes,
          })
          reject(error)
        })
    })
  },
  updateState(patientNo: string, auditEntries: Array<AuditEntry>, state) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .put('/web/patient/state', {
          patientNumber: patientNo,
          currentClientTime,
          auditEntries,
          state,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('updateState error', error, {
            patientNo,
            auditEntries,
          })
          reject(error)
        })
    })
  },
  updateTreatmentArm(request) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/treatmentArm', request)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          reject(error)
        })
    })
  },
  updateDosingRegimen(request) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/bolusRegimen', request)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          reject(error)
        })
    })
  },
  update(
    patientNo: string,
    versionReason: string,
    auditEntries: Array<AuditEntry>,
    updates
  ) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .put('/web/patient', {
          ...updates,
          patientNumber: patientNo,
          currentClientTime,
          versionReason,
          auditEntries,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('update patient error', error, {
            patientNo,
            currentClientTime,
            versionReason,
            auditEntries: JSON.stringify(auditEntries),
            ...updates,
          })
          reject(error)
        })
    })
  },
}
