import Timestamp from '@/utils/audit/Timestamp'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default class AuditEntry {
  readonly id: number
  readonly fieldName: string
  readonly newValue: string
  readonly timestamp: Timestamp

  constructor(id: number, fieldName: string, newValue: string) {
    this.id = id
    this.fieldName = fieldName
    this.newValue = newValue
    this.timestamp = dateTimeWithTimeZone()
  }
}
