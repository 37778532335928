import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: routes.map(route => ({
    name: route.name,
    path: route.path,
    component: route.component,
    meta: route.meta,
    props: route.props,
    children: route.children,

    beforeEnter: (to, from, next) => {
      document.title = route.title ? route.title : 'Dashboard'

      next()
    },
  })),
})

export default router
