<template>
  <svg
    id="Settings"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect id="Bounding_box" width="24" height="24" fill="rgba(255,255,255,0)" />
    <g id="BI_-_Cog" transform="translate(1.5 1.5)">
      <path
        id="Path_1558"
        d="M9.667,5.662,10.2,3.026A1.328,1.328,0,0,1,11.459,2h2.081A1.329,1.329,0,0,1,14.8,3.026l.528,2.635L17.569,4.17a1.33,1.33,0,0,1,1.62.169L20.66,5.81a1.33,1.33,0,0,1,.17,1.62L19.339,9.667l2.635.528A1.328,1.328,0,0,1,23,11.459v2.081A1.329,1.329,0,0,1,21.974,14.8l-2.635.528,1.491,2.237a1.33,1.33,0,0,1-.169,1.62L19.19,20.66a1.33,1.33,0,0,1-1.62.17l-2.237-1.491L14.8,21.974A1.328,1.328,0,0,1,13.541,23H11.459A1.329,1.329,0,0,1,10.2,21.974l-.528-2.635L7.431,20.83a1.33,1.33,0,0,1-1.62-.169L4.34,19.19a1.33,1.33,0,0,1-.17-1.62l1.491-2.237L3.026,14.8A1.328,1.328,0,0,1,2,13.541V11.459A1.329,1.329,0,0,1,3.026,10.2l2.635-.528L4.17,7.431a1.33,1.33,0,0,1,.169-1.62L5.81,4.34a1.33,1.33,0,0,1,1.62-.17L9.667,5.661Zm-4.05,1.1L6.972,8.793a1.575,1.575,0,0,1-1,2.418l-2.4.48v1.617l2.4.48a1.575,1.575,0,0,1,1,2.418L5.617,18.239l1.145,1.145,2.032-1.357a1.575,1.575,0,0,1,2.418,1l.48,2.4h1.617l.48-2.4a1.575,1.575,0,0,1,2.418-1l2.033,1.356,1.145-1.144-1.357-2.032a1.575,1.575,0,0,1,1-2.418l2.4-.48V11.692l-2.4-.48a1.575,1.575,0,0,1-1-2.418l1.356-2.033L18.238,5.616,16.207,6.973a1.575,1.575,0,0,1-2.418-1l-.48-2.4H11.691l-.48,2.4a1.575,1.575,0,0,1-2.418,1L6.761,5.617,5.616,6.762ZM12.5,16.7a4.2,4.2,0,1,1,4.2-4.2A4.2,4.2,0,0,1,12.5,16.7Zm0-1.575A2.625,2.625,0,1,0,9.875,12.5,2.625,2.625,0,0,0,12.5,15.125Z"
        transform="translate(-2 -2)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.25"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'IconCog' }
</script>
