<template>
  <div class="application-page">
    <router-view />

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/layout/AppFooter.vue'

export default { name: 'FullscreenLayout', components: { AppFooter } }
</script>
