<template>
  <svg
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="17"
      height="23"
      stroke="currentColor"
      stroke-width="2"
    />
    <path d="M6 7H13.5" stroke="currentColor" stroke-width="2" />
    <path d="M6 12H13.5" stroke="currentColor" stroke-width="2" />
    <path d="M6 17H13.5" stroke="currentColor" stroke-width="2" />
  </svg>
</template>

<script>
export default { name: 'IconSheet' }
</script>
