import { createNamespacedHelpers } from 'vuex'
import loadingState from '@/constants/loadingState'
import service from '@/services/pro-service'

export const proListModule = 'proList'

const proListGetters = [
  'proListItems',
  'proListLoadingState',
  'proListIsLoading',
  'proListLoadErrored',
]
Object.freeze(proListGetters)

const proListActions = [
  'proListLoadDataRequested',
  'proListReLoadDataRequested',
  'proListUpdateReviewStatus',
]
Object.freeze(proListActions)

const { mapGetters, mapActions } = createNamespacedHelpers(proListModule)

export const proListMapGetters = () => mapGetters(proListGetters)

export const proListMapActions = () => mapActions(proListActions)

let latestPatientNumberRequested: string = null

interface ProListState {
  items: Array<any>
  patientNumber?: string
  loadingState: string
}

interface IReviewApiResponse {
  dataItemId: number
  result: object
}

export default {
  namespaced: true,
  state: { items: [], loadingState: loadingState.INITIAL },
  getters: {
    proListItems: (state: ProListState) => {
      return state.items
    },
    proListLoadingState: (state: ProListState) => {
      return state.loadingState
    },
    proListIsLoading: (state: ProListState) => {
      return state.loadingState === loadingState.LOADING
    },
    proListLoadErrored: (state: ProListState) => {
      return state.loadingState === loadingState.LOAD_ERRORED
    },
  },
  mutations: {
    SET_PROLIST_LOADING(state: ProListState) {
      state.loadingState = loadingState.LOADING
    },
    CLEAR_PROLIST(state: ProListState) {
      state.items = []
      state.loadingState = loadingState.INITIAL
    },
    LOAD_PROLIST(state: ProListState, patientNumber: string) {
      latestPatientNumberRequested = patientNumber
      state.items = []
    },
    UPDATE_PROLIST(state: ProListState, data) {
      state.items = data.items || []
      state.loadingState = loadingState.LOAD_SUCCEEDED
    },
    UPDATE_PROLIST_FAILED(state: ProListState) {
      state.loadingState = loadingState.LOAD_ERRORED
    },
    UPDATE_PRO_REVIEW_STATUS(
      state: ProListState,
      response: IReviewApiResponse
    ) {
      let pro
      for (let i = 0; i < state.items.length; i++) {
        pro = state.items[i].pros.find(
          x => x.dataItemId === response.dataItemId
        )
        if (pro) {
          pro.review = response.result
          break
        }
      }
    },
  },
  actions: {
    proListUpdateReviewStatus({ commit }, response: IReviewApiResponse) {
      commit('UPDATE_PRO_REVIEW_STATUS', response)
    },
    async proListLoadDataRequested(
      { commit, dispatch },
      patientNumber: string
    ) {
      commit('SET_PROLIST_LOADING')
      commit('LOAD_PROLIST', patientNumber)
      dispatch(`globalProgress/globalStartLoadingRequested`, null, {
        root: true,
      })

      try {
        const response: {
          items?: any
          patientNumber?: string
        } = await service.getProsList(patientNumber)
        if (response?.patientNumber === latestPatientNumberRequested) {
          commit('UPDATE_PROLIST', response)
        }
      } catch (error) {
        commit('UPDATE_PROLIST_FAILED', error)
      } finally {
        dispatch('globalProgress/globalStopLoadingRequested', null, {
          root: true,
        })
      }
    },
    async proListReLoadDataRequested(
      { commit, dispatch },
      patientNumber: string
    ) {
      commit('SET_PROLIST_LOADING')
      dispatch(`globalProgress/globalStartLoadingRequested`, null, {
        root: true,
      })

      try {
        const response: {
          items?: any
          patientNumber?: string
        } = await service.getProsList(patientNumber)
        if (response?.patientNumber === latestPatientNumberRequested) {
          commit('UPDATE_PROLIST', response)
        } else {
          commit('CLEAR_PROLIST')
        }
      } catch (error) {
        commit('UPDATE_PROLIST_FAILED', error)
      } finally {
        dispatch('globalProgress/globalStopLoadingRequested', null, {
          root: true,
        })
      }
    },
  },
}
