<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V14C0 15.105 0.895 16 2 16H8.27734C8.62361 16.5956 9.26105 17 10 17C10.7389 17 11.3764 16.5956 11.7227 16H18C19.105 16 20 15.105 20 14V0H12C11.228 0 10.532 0.300297 10 0.779297C9.468 0.300297 8.772 0 8 0H0ZM2 2H8C8.551 2 9 2.449 9 3V14H2V2ZM12 2H18V14H11V3C11 2.449 11.449 2 12 2Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDiary',
}
</script>
