<template>
  <v-list-item-group color="primary">
    <v-list-item
      link
      :to="{ name: 'patient' }"
      :exact="true"
      data-testid="menu-insights"
      class="d-flex flex-column mb-4 py-2 px-0 mt-16"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconMosaic />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold">Summary</v-list-item-title>
    </v-list-item>

    <v-list-item
      v-if="bgmDataEnabled"
      link
      :to="{ name: 'patientBgmData' }"
      :exact="true"
      data-testid="menu-bgm-data"
      class="d-flex flex-column mb-4 py-2 px-0"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconMenuScatter />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold">BG values</v-list-item-title>
    </v-list-item>

    <v-list-item
      v-if="insulinsEnabled"
      link
      :to="{ name: 'patientDoseDiary' }"
      :exact="true"
      data-testid="menu-insulin-diary"
      class="d-flex flex-column mb-2 py-2 px-0"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconDiary />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold"
        >Dose<br />diary</v-list-item-title
      >
    </v-list-item>

    <v-list-item
      v-if="prescriptionsEnabled"
      link
      :to="{ name: 'prescription' }"
      :exact="true"
      data-testid="menu-prescription"
      class="d-flex flex-column mb-1 pt-2 pb-3 px-0"
    >
      <v-list-item-icon class="d-flex justify-center mb-special mr-0">
        <IconPen />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold"
        >Dose<br />prescription</v-list-item-title
      >
    </v-list-item>

    <v-list-item
      link
      :to="{ name: 'patientHypoOverview' }"
      :exact="true"
      data-testid="menu-hypo-overview"
      class="d-flex flex-column mb-4 py-2 px-0"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconMenuBloodDropO />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold"
        >Hypo<br />episodes</v-list-item-title
      >
    </v-list-item>

    <v-list-item
      v-if="prosEnabled"
      link
      :to="{ name: 'proQuestionnaires' }"
      :exact="true"
      data-testid="menu-pro-questionnaires"
      class="d-flex flex-column mb-4 py-2 px-0"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconSheet />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold">PROs</v-list-item-title>
    </v-list-item>

    <v-list-item
      link
      :to="{ name: 'settingsPatient' }"
      :exact="false"
      data-testid="menu-patient-settings"
      class="d-flex flex-column py-2 px-0"
      :class="{
        'patient-link-active': isLinkActive,
      }"
    >
      <v-list-item-icon class="d-flex justify-center mb-1 mr-0">
        <IconCog />
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold">Settings</v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import IconMosaic from '@/components/icons/IconMosaic'
import IconMenuScatter from '@/components/icons/IconMenuScatter'
import IconDiary from '@/components/icons/IconDiary'
import IconPen from '@/components/icons/IconPen'
import IconMenuBloodDropO from '@/components/icons/IconMenuBloodDropO'
import IconCog from '@/components/icons/IconCog'
import IconSheet from '@/components/icons/IconSheet'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'

export default {
  name: 'PatientNavigation',
  components: {
    IconSheet,
    IconCog,
    IconMenuBloodDropO,
    IconPen,
    IconDiary,
    IconMenuScatter,
    IconMosaic,
  },
  props: {
    patientNo: { type: String, required: true },
  },
  computed: {
    ...trialSettingsMapGetters(),
    bgmDataEnabled() {
      return this.featureFlags.bgmsEnabled
    },
    prescriptionsEnabled() {
      return this.featureFlags.prescriptionsEnabled
    },
    insulinsEnabled() {
      return this.featureFlags.insulinsEnabled
    },
    prosEnabled() {
      return this.featureFlags.prosEnabled
    },
    featureFlags() {
      return this.trialFeatureFlags(this.patientNo)
    },
    isLinkActive() {
      return (
        this.$route.name === 'settingsApp' ||
        this.$route.name === 'settingsPatient' ||
        this.$route.name === 'settings'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item--active,
.patient-link-active {
  background-color: $nn-SB_T98;
  color: $nn-sea-blue;
}
.patient-link-active {
  .v-list-item__title {
    color: $nn-sea-blue;
  }
  &::before {
    background-color: $nn-sea-blue;
    opacity: 0.12;
  }
  svg {
    color: $nn-sea-blue;
  }
}
.v-list-item::after {
  min-height: auto;
}
.v-list-item__title {
  font-size: 11px !important;
  text-align: center;
  line-height: 14px !important;
}
.v-list-item__icon {
  align-self: inherit;
}
.mb-special {
  margin-bottom: 6px !important;
}
</style>
