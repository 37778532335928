const getters = {
  newPatientInfo: state => state.patientInfo,
  newPatientInfoAuditEntries: state =>
    state.patientInfoAuditLog.getAuditEntries(),
  newPatientAppSettings: state => state.appSettings,
  newPatientAppSettingsAuditEntries: state =>
    state.appSettingsAuditLog.getAuditEntries(),
  newPatientDoseRules: state => state.doseRules,
  newPatientFeatureFlags: state => state.featureFlags,

  newPatientPrescription: state => id => {
    return state.treatments[id]
  },
}

export default getters
